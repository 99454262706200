.analytics{
    flex: 5;
    padding: 90px 25px 90px 125px;
}

.filterRowDD {
    /*display: flex;
    justify-content: flex-end;*/
    width: 390px;
}
.filterRow {
    display: flex;
    justify-content: flex-end;
}

.flexBox {
    display: flex;
}

.rightside-btn {
    float: right;
    margin-top: 50px;
}

.tableButtonRow {
    display: flex;
    justify-content: end;
}

.exportbutton .exportbutton_other {
    border-radius: 0px !important;
}