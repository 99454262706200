.newUser{
flex: 5;
padding: 90px 25px 90px 25px;
}

.newUserForm{
    /* display: flex; 
    flex-wrap: wrap; */
}

.newUserForm h2{
    width: 100%;
    margin-top:20px;
}


.companyInfo{
    display: flex; 
    flex-wrap: wrap;
}

.companyInfo h2{
    width: 100%;
    margin-top:20px;
}

.newUserItem{
    width: 600px;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-right: 20px;
    margin-bottom: 40px;
}
.newUserItem>label{
    margin-bottom: 10px;
    font-weight: 600;
    color: #003a41;
    font-size: 16px;
}



.newUserItem .templateButtonContainer>label{
    margin-bottom: 0px;
    font-weight: 600;
    color: #003a41;
    font-size: 16px;
}


.newUserItem span.mandatory{
    margin-bottom: 10px;
    font-weight: 600;
    color: red;
    font-size: 16px;
}
.newUserItem>input{
   height: 50px;
   padding: 5px 5px 5px 10px;
   border: 1px solid #cfd2db;
   border-radius: 5px;
   background-color: #f9fafb;
   font-size: 16px;
}

.newUserItem>textarea{
    height: 250px;
    padding: 5px 5px 5px 10px;
    border: 1px solid #cfd2db;
    border-radius: 5px;
    background-color: #f9fafb;
    font-size: 16px;
 }

.newUserButton{
    width: 100%;
    border-radius: 5px;
    background-color: #1d2740;
    border: none;
    margin-top:30px;
    cursor: pointer;
    font-weight: 600;
    padding:7px 10px;
    color: white;
    height: 46px;
    font-size: 18px;
}