.headerMenus img{width:25px; height:25px; margin:0px 10px 0px 0px  !important;}
.headerMenus ul{margin:0px !important; padding: 0px; list-style: none;}
.headerMenus li{margin:5px 0px !important; list-style: none; padding:10px;}
.headerMenus li:hover{background: #FFDBDD;
    border-radius: 10px;
    color: #EF5663;}

    .headerMenus li:hover a{color: #EF5663;}
    .headerMenus li:hover a svg{filter: invert(41%) sepia(53%) saturate(2136%) hue-rotate(326deg) brightness(102%) contrast(87%);}
    .headerMenus li:hover a img.filter-red{ filter: invert(41%) sepia(53%) saturate(2136%) hue-rotate(326deg) brightness(102%) contrast(87%); }
.headerMenus{

font-family: 'Manrope';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
/* identical to box height, or 150% */


/* Greys/Blue Grey/500 */

color: #B5B3B4;}


.headerMenus a{
color: #B5B3B4;}