/* build safety statement */
.build-safety-statement {
  flex: 5;
  padding: 90px 25px 90px 125px;
}

/* Draggable css */
.drag-container {
  border-radius: 12px;
}
.drag-table-header {
  background-color: #fff;
  display: flex;
  border-radius: 12px 12px 0px 0px;
}
.drag-table-header p {
  padding: 24px 30px;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #151d48;
  border-right: 1px solid #e2e2e2;
}
.drag-table-header p:nth-child(1) {
  width: 32%;
}
.drag-table-header p:nth-child(2) {
  width: 10%;
}
.drag-table-header p:nth-child(3) {
  width: 10%;
}
.drag-table-header p:nth-child(4) {
  width: 10%;
}
.drag-table-header p:nth-child(5) {
  width: 20%;
}
.drag-table-header p:nth-child(6) {
  width: 10%;
}
.drag-table-header p:nth-child(7) {
  width: 8%;
  border: 0;
}

.drag-menu {
  margin: 2em;
  display: flex;

}
.drag-new-card {
  font-size: 1em;
  color: grey;
  min-width: 100px;
  text-align: center;
  cursor: pointer;
}

.card-container {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 0px 0px 12px 12px;
  width: calc(100% - 40px);
}
.fire.titlebar {
  display: flex;
  justify-content: space-between;
  background: #FEF2F2;
}

.drag-input {
  padding: 8px;
  width: 100%;
  font-size: 14px;
  margin: 5pxÍ;
}

.drag-title {
  padding: 10px 30px;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #151d48;
  text-overflow: ellipsis;
  width: 90%;
}

.drag-cross {
  padding: 10px 20px;
  cursor: pointer;
  text-align: center;
  color: grey;
  width: 8%;
  max-width: 180px;
}

.task-list {
  /* padding: 8px; */
  background-color: #fff;
  /* min-height: 100px; */
  /* height: 100%; */
}

/*  */
.text-content {
  border-bottom: 1px solid #e2e2e2;
  /* padding: 8px 30px; */
  /* margin-bottom: 8px; */
  /* border-radius: 2px; */
  background-color: white;
  width: 100%;
}
.text-content.row-content {
  display: flex;
  align-items: center;
}

.row-content p {
  padding: 10px 30px;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #151d48;
  border-right: 1px solid #e2e2e2;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.row-content p:nth-child(1) {
  width: 32%;
}
.row-content p:nth-child(2) {
  width: 10%;
}
.row-content p:nth-child(3) {
  width: 10%;
}
.row-content p:nth-child(4) {
  width: 10%;
}
.row-content p:nth-child(5) {
  width: 20%;
}
.isViewStatement p:nth-child(5) {
  width: 28%;
}
.row-content p:nth-child(6) {
  width: 10%;
  padding: 10px 30px;
  justify-content: center;
}
.row-content p:nth-child(7) {
  width: 8%;
  border: 0;
  padding: 10px 30px;
  justify-content: center;
}

.section-input{
  width: 100%;
}
.section-input > input {
  padding: 5px 5px 5px 10px;
  border: 0px;
  border-radius: 5px;
  background-color: #ffffff;
  font-size: 14px;
  line-height: 16px;
  width: 100%;
}
.placeholder-text {
  font-size: 18px;
  line-height: 14px;
}

.one-line-text{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.task-container,
.new-task-bar {
  display: flex;

}
.new-task-button {
  padding: 8px;
  margin: 8px;
  cursor: pointer;
  text-align: right;
  color: grey;
}

/* custom checkbox */
.custom-radio[type="radio"]:checked,
.custom-radio[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
.custom-radio[type="radio"]:checked + label,
.custom-radio[type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}
.custom-radio[type="radio"]:checked + label:before,
.custom-radio[type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 24px;
  height: 24px;
  border: 1px solid #ddd;
  background: #fff;
}
.custom-radio[type="radio"]:checked + label:after,
.custom-radio[type="radio"]:not(:checked) + label:after {
  content: "";
  /* width: 12px;
  height: 12px;
  background: #f59e0b;
  position: absolute;
  top: 6px;
  left: 6px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease; */
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-right-style: solid;
  border-right-width: 2px;
  content: "";
  display: inline-block;
  height: 12px;
  left: 8px;
  position: absolute;
  top: 4px;
  transform: rotate(45deg);
  width: 8px;
}
.custom-radio[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
.custom-radio[type="radio"]:checked + label:after {
  /* opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1); */
}

.safety-text-item{
  margin-top: 20px;
  width: 80%  ;
}