.template{
    flex:5;
    padding: 0px;

}

.userTtileContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.userAddButton{
    width: 80px;
    border-radius: 5px;
    background-color: teal;
    border: none;
    padding: 5px;
    cursor: pointer;
    color: white;
    font-size: 16px;
}

.userContainer{
    display: flex;
    margin-top: 20px;
}

.userShow{
    flex: 1;
    padding: 20px;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75); 
    box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
}

.userUpdate{
    flex: 2;
    padding: 20px;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75); 
    box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
    margin-left: 20px;
}

.userShowTop{
    display: flex;
    align-items: center;
}


.userShowImage{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}

.userShowTopTitle{
    display: flex;
    flex-direction: column;
    margin-left: 20px;
}

.userShowUsername{
    font-weight: 600;
}


.userShowUserTitle{
    font-weight: 300;
    font-size: 14px;
}

.userShowBottom{
margin-top: 20px;
}

.userShowTtitle{
    font-size: 14px;
    font-weight: 600;
    color: rgb(175, 175, 175);
}

.userShowInfo{
    display: flex;
    align-items: center;
    margin: 20px 0;
    color: #444444;
}

.userShowicon{
    font-size: 16px !important;
}

.userShowInfoTitle{
    margin-left: 10px;
}

.userUpdateForm{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}
.userUpdateTitle{
    font-size: 24px;
    font-weight: 600;
}
.userUpdateItem{
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.userUpdateItem>label{
    margin-bottom: 5px;
    font-size: 14px;

}

.userUpdateInput{
    width: 250px;
    height: 30px;
    border: none;
    border-bottom: 1px solid gray;

}

.userUpdateImage{
    width: 100px;
    height: 100px;
    border-radius: 10px;
    object-fit: cover;
    margin-right: 20px;
}

.userUpdateRight{
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}

.userUpdateUpload{
    display: flex;
    align-items: center;
}

.userUpdateIcon{
    cursor: pointer;
}

.userUpdateButton{
    border: none;
    border-radius: 5px;
    padding: 5px;
    cursor: pointer;
    color: white;
    background-color: darkblue;
    font-weight: 600;
}

.return .actionElementButton{text-decoration: none !important;}
.return .actionButtonText{text-decoration: none !important;}

.return a{text-decoration: none !important;}



.returnHeaderIcon {
    color: white;
    background-color: #1d2740;
    padding: 8px;
    border-radius: 8px;
    font-size: 30px !important;
    margin-right: 10px;
}

.pageHeader a{ 
    align-items: center;
    display: flex;
}