.border-top {
    border-top: 2px solid #dee2e6;
    margin: 7% 0;
}
.round-channel-red , .round-channel-success ,.round-channel-pause{

    border-radius: 82px;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.round-channel-red  {
    background-color: #fde6e8;
}
.round-channel-success{
    background-color: #DFF3E7;
}
.round-channel-pause{
    background-color: #F0EDEE;
}
.fa-close {
    color: #ef5663;
}
.fa-check {
    color: #27AE60
}
.time-text{
    font-size: 12px;
}
.exiting-task-main{
    height: 59vh;
    overflow-y: auto;
    overflow-x: hidden;
}
.active-task{
    border: 2px solid #fde6e8;
    border-radius: 15px;
}
.sidepanel-voice{
    border-right: 1px solid #DFF3E7;
}
.voice-ch-main-div{
    background-color: #fde6e8;
    width: 100%;
    height: 531px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 8% 2%;

}
.user-call-info{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.user-call-action{
    display: flex;
    justify-content: center;
}

@media (min-width: 600px){
    .custom-tab-class {
        min-width: 20% !important;
    }
}
