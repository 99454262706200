.mt-20 {
    margin-top:20px !important;
}

.ml-20 {
    margin-left:20px !important;
}

.ml-10 {
    margin-left:10px !important;
}

.ml-5 {
    margin-left:5px !important;
}

.ml-28 {
    margin-left:28px !important;
}

.importOptions {
    margin-top: 25px !important;
    margin-left:0px !important;
}

.importsubmit {
    margin-top:50px;
    padding: 25px !important;
}

.import-optiondiv:hover {
    cursor: pointer;
}

.import-optiondiv {
    border: 1px solid #cfd2db;
    border-radius: 15px;
}

.checked-import-method {
    border: 2px solid #005CC8;
}

.copypaste-option {
    margin-left: 15px !important;
}

#import-option-radio {
    float: right;
    margin-right: 20px;
    height: 20px;
    width: 20px;
}

.file_browse_div {
    position: relative;
    border: 1px solid red;
    width: 50px;
    height: 30px;
    line-height: 30px;
    text-align: center;
 }

.file_upload {
    opacity: 0.0;

   /* IE 8 */
   -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";

   /* IE 5-7 */
   filter: alpha(opacity=0);
 
   /* Netscape or and older firefox browsers */
   -moz-opacity: 0.0;

   /* older Safari browsers */
   -khtml-opacity: 0.0;

   position: absolute;
   top: 0;
   left: 0;
   bottom: 0;
   right: 0;
   width: 100%;
   height:100%;
}

.fileDataTable {
    overflow-x: auto;
}

.mappingColum-grid table {
    font-family: "IBM Plex Sans, sans-serif";
    font-size: 0.875rem;
    border-collapse: collapse;
    width: 100%;
}

.mappingColum-grid td, .mappingColum-grid th {
    /*border: 1px solid grey !important;*/
    text-align: left;
    /*padding: 5px;*/
    min-width: 205px;
}

.mappingColum-grid th:hover {
    cursor: pointer;
}

.MuiDialog-paper {
    min-width: 400px;
}

.mappingColumn-match-table td .MuiSvgIcon-root, .mappingColumn-match-table th .MuiSvgIcon-root {
    height: 20px !important;
}

/***** BrowseFile component css START*******/

.browsefile_div {
    padding:30px 0px 15px 0px;
    align-items: center;
    justify-content: center;
    /*font-size: 20px;*/
}

.browsefile_div input {
    display: none;
}

.browsefile_div p {
    /*color: var(--color-headings);*/
}

.browse-container-item {
    padding-top: 0px !important;
}

/***** BrowseFile component css END*******/
/***** MappingColumn component css START*******/
.column-header-box {
    display: inline-flex;
    width:100%;
}

.column-header-name-box {
    width: 80%;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}

.column-header-name-checkbox {
    width: 20%;
}

.col-cbox {
    float: right;
    height: 100%;
    width: 100%;
}

.unrecognized-column {
    /*border: 1.5px solid red !important;
    background-color: #e7cfcf;*/
    padding: 10px 16px;
    /*border-radius: 10px 0px 0px 0px;*/
    border: 0.5px solid var(--blue-grey-400, #D8D5D6) !important;
    background: #A73305;
    color: #FFFFFF;
}

.unrecognized-column-data-style {
    /*color: red;*/
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    padding: 10px 16px;
    /*border-radius: 10px 0px 0px 0px;
    border: 0.5px solid var(--blue-grey-400, #D8D5D6) !important;*/
    background: var(--secondary-00, #FBCFBC);
}

.selected-column-header-style {
    /*border: 1.5px solid rgb(23, 161, 23) !important;
    background-color: #d0efe5;*/
    padding: 10px 16px;
    border-radius: 10px 0px 0px 0px;
    border: 0.5px solid var(--blue-grey-400, #D8D5D6) !important;
    background: var(--secondary-00, #FFF);
}

.selected-column-header-style span {
    width: 80%;
}

.selected-column-data-style {
    color: #19cf96;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    padding: 10px 16px;
    border-radius: 10px 0px 0px 0px;
    border: 0.5px solid var(--blue-grey-400, #D8D5D6) !important;
    background: var(--secondary-00, #FFF);
}

.modal-box {
    position: 'absolute' as 'absolute';
    top: '50%';
    left: '50%';
    transform: 'translate(-50%, -50%)';
    width: 400;
    background-color: 'background.paper';
    border: '2px solid #000';
    box-shadow: 24;
    padding-top: 2;
    px: 4;
    padding-bottom: 3;
}

.match-table-container{
    width: 100%;
    /*height: 100%;*/
    overflow: hidden;
    scroll-behavior: smooth;
}

.match-table {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    padding-right: 17px; /* Increase/decrease this value for cross-browser compatibility */
    box-sizing: content-box; /* So the width will be 100% + 17px */
}

/*.left-scroll-button {
    position: absolute;
    width: 50px !important;
    height: 50px !important;
    margin-top: 50px;
    left: 19%;
    background: #ef5864;
    border-radius: 20px;
    color: #fff;
}

.right-scroll-button {
    position: absolute;
    width: 50px !important;
    height: 50px !important;
    background: #ef5864;
    border-radius: 20px;
    bottom: 63%;
    right: 24.5%;
    color: #fff;
}*/

.left-scroll-button {
    width: 40px !important;
    height: 40px !important;
    margin-top: 15px;
    background: #ef5864;
    border-radius: 20px;
    color: #fff;
}

.right-scroll-button {
    width: 40px !important;
    height: 40px !important;
    margin-top: 15px;
    background: #ef5864;
    border-radius: 20px;
    color: #fff;
    margin-left: 15px;
}

/***** MappingColumn component css END*******/

.file-selected {
    padding: 30px;
}

.file-selected-check-icon{
    float: right;
}

.dialogTitle-component button {
    /*padding: 0px !important;*/
}

.file-upload-div {
    padding-top: 15px;
}

/*
.edit-list-icon {
    border-radius: 5px !important;
    margin-left: 10px;
    padding: 0px 0px;
    min-width: 40px;
    height: 30px !important;
    background-color: #ef5864;
    color: #fff;
}

.edit-list-icon:hover {
    color: #ef5864;
    background-color: #fff;
    border: 1px solid #ef5864;
}*/

.import-contact-header {
    color: var(--blue-grey-900, #2C2126);
    font-family: Manrope;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
}

.import-contact-p {
    color: var(--blue-grey-800, #4C4448);
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}

.mt--50 {
    margin-top: -50px;
}

.scroll-button-box {
    width: 36px;
    height: 36px;
    flex-shrink: 0;
    border-radius: 20px;
}

.scroll-button-box img{ 
    width: 20px;
    height: 20px;
    flex-shrink: 0;
}

.no-scroll-table {
    background: #FFEDF0;
}

.have-scroll-table {
    background: #F05865;
}

.left-s-icon {
    margin: 8px 6px;
}

.right-s-icon {
    margin: 8px 9px;
}

.scroll-button-div {
    display: flex;
    margin-top: 20px !important;
}

.select-method-options {
    display: flex;
    /*min-width: 310px;*/
    padding: 40px 35px !important;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.select-method-options input {
    display: none;
}
