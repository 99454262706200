.templates{
    flex:5;
    padding: 90px 25px 90px 125px;
}

.templates .MuiDataGrid-root{
    max-height: calc(100vh - 300px) !important;
}

.label-p {
    float:left;
    font-weight:700;
}

.sms-body {
    width: 100%;
    min-height: 250px;
    padding:15px;
    border-color: #ccced1;
}

.hide-grid {
    display: none !important;
}

.mb--25 {
    margin-bottom: -25px !important;
}