.topbar {
  width: 100%;
  height: 95px;
  background-color: #1d2740;
  position: sticky;
  top: 0px;
  z-index: 999;
}

.topbarWrapper {
  height: 100%;
  padding: 10px 20px;
  display: flex;
  align-content: center;
  justify-content: space-between;
}

.topbarIconContainer {
  position: relative;
}
.topRight {
  display: flex;
  align-items: center;
}

.topIconBadge {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: red;
  border-radius: 50%;
  color: white;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}

.topAvatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
}

.topLeft {
  flex: 1;
  background-color: #1d2740;
}

.topRight {
  flex: 5;
  background-color: white;
}
.logintopbar {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-bottom: 1px solid gray;
  height: 80px;
  width: 100%;
  position: sticky;
  top: 0%;
  z-index: 10;
  gap: 20px;
}
.logintopbar a{
    color: gray;
}
