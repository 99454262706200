.chat {
  display: flex;
  padding: 60px 0px 0px 65px;


}

.chatWrapper {
  display: flex;
  flex-wrap: wrap;
  height: calc(100vh - 90px);
}

.chatTaskPanel {
  flex: 1;
  align-items: baseline;
  flex-direction: column;
  display: flex;
  background-color: coral;
  padding: 0px;
}

.chatTaskPanelTop {
  background-color: aquamarine;
  padding: 5px;
  display: flex;
  width: 100%;
}

.chatTaskPanelBottom {
  background-color: grey;
  padding: 5px;
  display: flex;
  width: 100%;
}

.chatPanel {
  flex: 7;
  background-color: white;
  padding: 5px;
}

.chatPanelTop {
  background-color: blue;
  padding: 5px;
  height: calc(100vh - 80px);
}

.chatPanelBottom {
  background-color: black;
  padding: 0px;
  bottom: 0px;

  position: relative;
  min-height: 80px;
}




aside {
  display: flex;
  flex: 1;
  min-width: 300px;
  background-color: #1d2740;
  flex-direction: column;
  font-size: 15px;
  height: calc(100vh - 65px);
  vertical-align: top;
}

main {
  display: flex;
  flex-direction: column;
  flex: 5;
  height: calc(100vh - 65px);
  font-size: 15px;
  vertical-align: top;
}

aside header {
  padding: 30px 20px;
}

aside input {
  width: 100%;
  height: 50px;
  line-height: 50px;
  padding: 0 50px 0 20px;
  background-color: #ef56634e;
  border: none;
  border-radius: 3px;
  color: #fff;
  background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/1940306/ico_search.png);
  background-repeat: no-repeat;
  background-position: 170px;
  background-size: 40px;
}

aside input::placeholder {
  color: #fff;
}

aside ul {
  padding-left: 0;
  margin: 0;
  list-style-type: none;
  overflow-y: scroll;

}

aside li {
  padding: 10px 0;
}

aside li:hover {
  background-color: #5e616a;
}

h2,
h3 {
  margin: 0;
}

aside li img {
  border-radius: 50%;
  margin-left: 20px;
  margin-right: 8px;
}

aside li div {
  display: inline-block;
  vertical-align: top;
  margin-top: 12px;
}

aside li h2 {
  font-size: 14px;
  color: #fff;
  font-weight: normal;
  margin-bottom: 5px;
}

aside li h3 {
  font-size: 12px;
  color: #7e818a;
  font-weight: normal;
}

.status {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 7px;
}

.green {
  background-color: #58b666;
}

.orange {
  background-color: #ff725d;
}

.blue {
  background-color: #6fbced;
  margin-right: 0;
  margin-left: 7px;
}

main header {
  height: 110px;
  padding: 30px 20px 30px 40px;
}

main header>* {
  display: inline-block;
  vertical-align: top;
}

main header img:first-child {
  border-radius: 50%;
}

main header img:last-child {
  width: 24px;
  margin-top: 8px;
}

main header div {
  margin-left: 10px;
  margin-right: 145px;
}

main header h2 {
  font-size: 16px;
  margin-bottom: 5px;
}

main header h3 {
  font-size: 14px;
  font-weight: normal;
  color: #7e818a;
}

#chat {
  padding-left: 0;
  margin: 0;
  list-style-type: none;
  overflow-y: scroll;
  height: calc(100vh - 155px);
  border-top: 2px solid #fff;
  border-bottom: 2px solid #fff;
}

.bg-light {
  background-color: #f8f9fa !important;
}

.bg-theme-color {
  background-color: #EF5663 !important;
}

.act-chat {
  background-color: #EF5663 !important;
  border-color: #EF5663 !important;
  color: #fff !important;
}

.act-chat h6,
.act-chat p {
  color: #fff !important;
}

.conv {
  display: flex;
  /* gap: 15px; */
}

.lightborder {
  border: 1px solid rgba(0, 0, 0, 0.12) !important
}


#chat::-webkit-scrollbar {
  width: 5px;
}

#chat::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

#chat::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

#chat li {
  padding: 10px 30px;
}

#chat h2,
#chat h3 {
  display: inline-block;
  font-size: 13px;
  font-weight: normal;
}

#chat h3 {
  color: #bbb;
}

#chat .entete {
  margin-bottom: 5px;
}

#chat .message {
  padding: 20px;
  color: #fff;
  line-height: 25px;
  max-width: 90%;
  display: inline-block;
  text-align: left;
  border-radius: 5px;
}


#chat .me {
  text-align: right;
}

#chat .you .message {
  background-color: #1d2740;
}

#chat .me .message {
  background-color: #ef5663;
}

#chat .triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 10px 10px;
}

#chat .you .triangle {
  border-color: transparent transparent #1d2740 transparent;
  margin-left: 15px;
}

#chat .me .triangle {
  border-color: transparent transparent #ef5663 transparent;
  margin-left: calc(100% - 35px);
}

main footer {
  height: 155px;
  padding: 20px 30px 10px 20px;
}

main footer textarea {
  resize: none;
  border: none;
  display: block;
  width: 100%;
  height: 80px;
  border-radius: 3px;
  padding: 20px;
  font-size: 13px;
  margin-bottom: 13px;
}

main footer textarea::placeholder {
  color: #ddd;
}

main footer img {
  height: 30px;
  cursor: pointer;
}

main footer a {
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
  color: #6fbced;
  vertical-align: top;
  margin-left: 333px;
  margin-top: 5px;
  display: inline-block;
}