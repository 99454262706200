@import url('https://fonts.googleapis.com/css?family=Manrope:regular,Light,Bold,ExtraBold,SemiBold,Medium,ExtraLight,italic');


.userlogin {
    flex: 5;
}

.userLoginContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #ffffff;
}

.userloginLeft {
    background-color: #ffffff;
    padding: 0px 0px;
    flex: 2;
    align-items: center;
    justify-content: center;
    align-content: center;
    display: flex;
    border-right: 1.5px solid #D8D5D6;
}


.userloginLeft span {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100vh;
    justify-content: center;
}

.logoadmintop {
    justify-content: left;
    width: 100%;
    height: 300px;
}

.userloginLeft span .logoadmintop img {
    height: 80px;
    margin: 20px;
}



.userloginLeft span .logoadminbottom {
    margin: 20px;
    /* justify-content: center; */
    align-items: center;
    justify-content: center;
    flex-direction: column;
    display: flex;
}

.userloginLeft span .logoadminbottom img {
    max-width: 600px;
    justify-content: center;
    width: 100%;
}

.userloginLeft span .logoadminbottom img.logo {
    width: 100%;
    max-width: 280px;
    margin-bottom: 50px;
}

.userLoginRight {
    padding: 0px 60px;
    background-color: #ffffff;
    flex: 2;
    align-items: center;
    justify-content: center;
    align-content: center;
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.login-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.loginForm {
    /* display: flex; 
     flex-wrap: wrap; */
    max-width: 600px;
    width: 100%;
}

.loginItem {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-right: 20px;
}

.loginItem>label {
    margin-bottom: 15px;
    color: #2C2126;
    font-size: 18px;
    font-family: "Manrope";
    font-weight: 600;
}

.loginItem>input {
    height: 50px;
    padding: 5px 5px 5px 10px;
    border: 1px solid #D8D5D6 !important;
    border-radius: 8px;
    color: #B5B3B4 !important;
    background-color: #ffffff !important;
    font-size: 15px;
    font-weight: 300;
    font-family: "Manrope";
}


.loginItem input[type="email"] {
    margin-bottom: 20px;
}

.loginButton {
    width: 100%;
    border-radius: 8px;
    background-color: #EF5663;
    border: none;
    margin-top: 30px;
    cursor: pointer;
    padding: 7px 10px;
    color: white;
    height: 60px;
    font-size: 18px;
    font-family: "Manrope";
    font-weight: 400;
}

.resetoptionsContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.resetoptionsLeft {
    color: #1d2740;
    font-size: 12px;
    padding-top: 5px;
    margin-left: 5px;
    align-content: center;
    justify-content: center;
}

.resetoptionsLeft input[type="checkbox"] {
    height: 20px;
    width: 20px;
    border: 1px solid #B5B3B4;
    border-radius: 4px;
    margin-right: 15px;
    -moz-appearance: none;
    -webkit-appearance: none;
    -o-appearance: none;
}

.forgotpasswordbtn {
    color: #ffffff;
    font-size: 12px;
    cursor: pointer;
    margin-right: 5px;
    border: none;
    background-color: transparent;
    padding: 5px 0px 0px 0px;
    align-items: center;
    display: flex;
}

.needsanaccount {
    color: #ffffff;
    font-size: 14px;
    /* cursor: pointer; */
    margin-right: 5px;
    margin-top: 20px;
    border: none;
    background-color: transparent;
    padding: 5px 0px 0px 0px;
    text-align: center;
    width: 100%;
}

.needsanaccount span {
    color: #ef5663;
    cursor: pointer;
}

.resetoptionsRight {
    color: #1d2740;
    font-size: 12px;
    padding-top: 5px;
    cursor: pointer;
    margin-right: 5px;
}

.resetDescription {
    color: gray;
    font-size: 14px;
    font-weight: 300;
    padding-left: 15px;
    padding-top: 5px !important;
}

.additionalLogin {
    color: #ffffff;
    text-align: center;
}

.additionalLogin h4 {
    color: #ffffff;
    font-size: 16px;
    text-align: center;
}

.additionalLogin p {
    color: #ffffff;
    font-size: 16px;
    text-align: center;
    margin: 10px 0px;
}

.google-btn {
    width: 100%;
    height: 46px;
    border-radius: 8px;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, .25);
    border: 1px solid #4f86ec;
    align-content: center;
    max-width: 400px;
    background-color: #4f86ec;
    padding: 1px;
    cursor: pointer;
}

.google-icon-wrapper {
    position: absolute;
    width: 46px;
    height: 42px;
    max-width: 46px;
    border-radius: 8px;
    background-color: #ffffff;
}

.google-icon {
    width: 26px;
    height: 26px;
    margin: 8px auto;
    display: block;
}

.btn-text {
    float: right;
    height: 42px;
    color: #ffffff;
    width: 100%;
    font-size: 16px;
    font-family: "Manrope";
    font-weight: 400;
    max-width: 550px;
    text-align: center;
    line-height: 42px;
    cursor: pointer;
}