.passwordReset{
    flex: 5;
    padding: 50px 40px;
}


.passwordResetForm{
    /* display: flex; 
    flex-wrap: wrap; */
}
.passwordResetItem{
    width: 400px;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-right: 20px;
}
.passwordResetItem>label{
    margin-bottom: 10px;
    font-weight: 600;
    color: #003a41;
    font-size: 14px;
}
.passwordResetItem>input{
   height: 50px;
   padding: 5px 5px 5px 10px;
   border: 1px solid #cfd2db;
   border-radius: 5px;
   background-color: #f9fafb;
   font-size: 16px;
}


.passwordResetButton{
    width: 100%;
    border-radius: 5px;
    background-color: #1d2740;
    border: none;
    margin-top:30px;
    cursor: pointer;
    font-weight: 600;
    padding:7px 10px;
    color: white;
    height: 46px;
    font-size: 18px;
}