.border-right {
    border-right: 1.5px solid var(--blue-grey-400, #D8D5D6);
}

.border-bottom {
    border-bottom: 1.5px solid var(--blue-grey-400, #D8D5D6);
}

.task-list-grid .heading {
    color: var(--blue-grey-900, #2C2126);
    font-family: Manrope;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
}

.task-list-grid .task-title {
    color: var(--blue-grey-900, #2C2126);
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 5px;
}

.task-list-grid .task-detail {
    color: var(--blue-grey-500, #B5B3B4);
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 5px;
}

.task-list-grid .task-icon {
    display: block;
    height: 95%;
    width: 95%;
    border-radius: 70px;
    padding: 11px 12px;
}

.video-icon     { background: #F0E9FB; }
.phone-icon     { background: #FDEEE7; }
.facebook-icon  { background: #E9EFFD; }
.instagram-icon { background: #FBE9F1; }
.msg-icon       { background: #FDF7E6; }
.email-icon     { background: #EAF3FC;}
.whatsapp-icon  { background: #DFF3E7;}
.right-icon     { background: #DFF3E7; }
.cross-icon     { background: #FDEEF0; }

.pl-10 {
    padding-left: 10px;
}

.task-padding {
    margin-top: 20px;
    padding: 7px;
    margin-right: 10px;
}

.task-list-li:hover {
    border-radius: 8px;
    border: 1px solid #FFA6CC;
    cursor: pointer;
}

.text-center {
    text-align: center;
}

.task-detail-grid {
    display: block !important;
}

.task-detail-icon-grid {
    border-bottom: 1.5px solid var(--blue-grey-400, #D8D5D6);
    padding-bottom: 20px;
}

.task-detail-grid .p-24{
    color: var(--blue-grey-900, #2C2126);
    font-family: Manrope;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
}

.task-detail-grid .p-14{
    color: var(--blue-grey-900, #2C2126);
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}