.newtemplate{
flex: 5;
padding: 0px;

}

.newTemplateForm{
    /* display: flex; 
    flex-wrap: wrap; */
}
.newTemplateItem{
    width: 1000px;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-right: 20px;
    margin-bottom: 40px;
}
.newTemplateItem>label{
    margin-bottom: 15px;
    font-weight: 600;
    color: #003a41;
    font-size: 16px;
}



.newTemplateItem .templateButtonContainer>label{
    margin-bottom: 0px;
    font-weight: 600;
    color: #003a41;
    font-size: 16px;
}


.newTemplateItem span.mandatory{
    margin-bottom: 10px;
    font-weight: 600;
    color: red;
    font-size: 16px;
}
.newTemplateItem>input{
   height: 50px;
   padding: 5px 5px 5px 10px;
   border: 1px solid #cfd2db;
   border-radius: 5px;
   background-color: #f9fafb;
   font-size: 16px;
}

.newTemplateItem>textarea{
    height: 250px;
    padding: 5px 5px 5px 10px;
    border: 1px solid #cfd2db;
    border-radius: 5px;
    background-color: #f9fafb;
    font-size: 16px;
 }

.newTemplateButton{
    width: 100%;
    border-radius: 5px;
    background-color: #1d2740;
    border: none;
    margin-top:30px;
    cursor: pointer;
    font-weight: 600;
    padding:7px 10px;
    color: white;
    height: 46px;
    font-size: 18px;
}