.emailNow{
    margin:0px auto;
    padding: 130px 40px;
}




.emailNowForm{
    /* display: flex; 
    flex-wrap: wrap; */
}
.emailNowItem{
    width: 600px;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-right: 20px;
    margin-bottom: 40px;
}
.emailNowItem>label{
    margin-bottom: 15px;
    font-weight: 600;
    color: #1d2740;
    font-size: 16px;
}



.emailNowItem .templateButtonContainer>label{
    margin-bottom: 0px;
    font-weight: 600;
    color: #1d2740;
    font-size: 16px;
}


.emailNowItem span.mandatory{
    margin-bottom: 10px;
    font-weight: 600;
    color: red;
    font-size: 16px;
}
.emailNowItem>input{
   height: 50px;
   padding: 5px 5px 5px 10px;
   border: 1px solid #cfd2db;
   border-radius: 5px;
   background-color: #f9fafb;
   font-size: 16px;
}

.emailNowItem>textarea{
    height: 250px;
    padding: 5px 5px 5px 10px;
    border: 1px solid #cfd2db;
    border-radius: 5px;
    background-color: #f9fafb;
    font-size: 16px;
 }

.emailNowButton{
    width: 100%;
    border-radius: 5px;
    background-color: #1d2740;
    border: none;
    margin-top:30px;
    cursor: pointer;
    font-weight: 600;
    padding:7px 10px;
    color: white;
    height: 46px;
    font-size: 18px;
}

.flex-row {
    flex-direction: row !important;
}


.ck-content{min-height:300px;}



.schedule{
    margin:0px auto;
        padding: 130px 40px;
    }
    
    .scheduleForm{
        /* display: flex; 
        flex-wrap: wrap; */
    }
    .scheduleItem{
        width: 600px;
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        margin-right: 20px;
        margin-bottom: 40px;
    }
    .scheduleItem>label{
        margin-bottom: 15px;
        font-weight: 600;
        color: #003a41;
        font-size: 16px;
    }
    
    
    
    .scheduleItem .templateButtonContainer>label{
        margin-bottom: 0px;
        font-weight: 600;
        color: #003a41;
        font-size: 16px;
    }
    
    
    .scheduleItem span.mandatory{
        margin-bottom: 10px;
        font-weight: 600;
        color: red;
        font-size: 16px;
    }
    .scheduleItem>input{
       height: 50px;
       padding: 5px 5px 5px 10px;
       border: 1px solid #cfd2db;
       border-radius: 5px;
       background-color: #f9fafb;
       font-size: 16px;
    }
    
    .scheduleItem .react-datetime-picker__wrapper{
        height: 40px !important;
       padding: 5px 5px 5px 10px;
       border: 1px solid #cfd2db;
       border-radius: 5px;
       background-color: #f9fafb;
       font-size: 16px;
       color: #757575;
    }
    
    
    
    
    .scheduleItemFile{display: flex; justify-content: space-between; flex-direction: column; max-width: 600px;margin-top: 10px;
        margin-right: 20px;
        margin-bottom: 40px;}
    
        .scheduleItemFileText{color:#757575; font-size: 14px; padding:5px;}
        .scheduleItemFileText a{color:#1d2740;; font-size: 14px; }
    
    .scheduleItemFile>input{
        height: 50px;
        padding: 5px 5px 5px 0px;
        font-size: 16px;
     }
    
    
    
     .scheduleItemFile>label{
        margin-bottom: 15px;
        font-weight: 600;
        color: #003a41;
        font-size: 16px;
    }
    
    .scheduleItemFile span.mandatory{
        margin-bottom: 10px;
        font-weight: 600;
        color: red;
        font-size: 16px;
    }
    
    .scheduleItem>textarea{
        height: 250px;
        padding: 5px 5px 5px 10px;
        border: 1px solid #cfd2db;
        border-radius: 5px;
        background-color: #f9fafb;
        font-size: 16px;
     }
    
    .scheduleButton{
        width: 100%;
        border-radius: 5px;
        background-color: #1d2740;
        border: none;
        margin-top:30px;
        cursor: pointer;
        font-weight: 600;
        padding:7px 10px;
        color: white;
        height: 46px;
        font-size: 18px;
    }
    
    .flex-row {
        flex-direction: row !important;
    }