.usersignup{

    flex: 5;
 
    
 }

 .err {
    margin: 4px 0 0 0;
    font-size: 13px;
    color: #e64646;
  }
  


 .showpasswordImg{
    width: 24px;
    position: absolute;
    top: 54%;
    right: 17px;
 }


 .verifypasswordImg {
    width: 24px;
    position: absolute;
    top: 54%;
    right: 17px;
 }

 .css-1p3m7a8-multiValue{background: #F6F4F5;
    border-radius: 5px !important;font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    padding:5px 10px;
    /* identical to box height, or 150% */
    
    
    /* Greys/Blue Grey/500 */
    
    color: #B5B3B4;
    }


    .css-wsp0cs-MultiValueGeneric{
        font-family: 'Manrope' !important;
    font-style: normal !important;
    font-weight: 300 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    /* identical to box height, or 150% */
    
    
    /* Greys/Blue Grey/500 */
    
    color: #B5B3B4 !important;
    }

    .css-tj5bde-Svg{

        border: 1px solid #B5B3B4;
        border-radius: 100%;
    }
 
 .userSignupContainerMain{
     display: flex;
     align-items: center;
     justify-content: center;
     height: 100vh;
     background-color:#1d2740;
   
 }
 
 .usersignupLeft{
 
     background-color: #ffffff;
     padding: 0px 0px;
    flex: 2;
     align-items: center;
     justify-content: center;
     align-content: center;
     display: flex;
     flex-direction: column;
 }
 
 
 .usersignupLeft span{
    display: flex;
    align-items: center;
    width: 100%;
    height: 100vh;
    justify-content: center;
 }

 .usersignupLeft span .logoadminbottom img.logo {
    width: 100%;
    max-width: 280px;
    margin-bottom: 50px;
}
 

.logoadmintop{
   justify-content: left;
    width: 100%;
    height: 300px;
    
}

.usersignupLeft span .logoadmintop img{

     height: 80px;
     margin: 20px;
     
 }


 .usersignupLeft span .logoadminbottom img {
    max-width: 600px;
    justify-content: center;
    width: 100%;
}
 


 .usersignupLeft span .logoadminbottom{

    margin: 20px;
    /* justify-content: center; */
    align-items: center;
    justify-content: center;
    flex-direction: column;
    display: flex;
   
 }

 
 
 
 .userSignupRightMain{
    padding: 0px 60px;
    background-color: #ffffff;
    flex: 2;
    align-items: center;
    justify-content: center;
    align-content: center;
    display: flex;
    flex-direction: column;
    height: 100vh;
    border-left: 1.5px solid #D8D5D6;
 }
 
 
 .signup-wrapper {
     display: flex;
     flex-direction: column;
     align-items: center;
 }
 
 .signupFormMain{
    max-width: 600px;
    width: 100%;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
 }
 .signupItem{
     position:relative;
     width: 100%;
     display: flex;
     flex-direction: column;
     margin-top: 10px;
     margin-right: 20px;
 }
 .signupItem>label{
    margin-bottom: 15px;
    color: #2C2126;
    font-size: 18px;
    font-family: "Manrope";
    font-weight: 600;
 }
 .signupItem>input{
    height: 50px;
    padding: 5px 5px 5px 10px;
    border: 1px solid #D8D5D6 !important;
    border-radius: 8px;
    color: #B5B3B4 !important;
    background-color: #ffffff !important;
    font-size: 15px;
    font-weight: 300;
    font-family:"Manrope" ;
    margin-bottom: 10px;
 }
 

 .css-13cymwt-control{
  
 }


 
 .signupButtonMain{
     width: 100%;
     border-radius: 5px;
     background-color: #ef5663;
     border: none;
     margin-top:30px;
     cursor: pointer;
     font-weight: 400;
     padding:7px 10px;
     color: white;
     height: 60px;
     font-size: 18px;
     font-family: 'Manrope';
     font-style: normal;
 }
 
 .resetoptionsContainer{
 display: flex;
 justify-content: space-between;
 align-items:center ;
 }
 
 .resetoptionsLeft{
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #B5B3B4;
     padding-top: 5px;
     margin-left: 5px;
     align-content: center;
     justify-content: center;
 
 
 }

 .resetoptionsLeft input[type="checkbox"]{

height:20px;
width: 20px;
border: 1px solid #B5B3B4;
border-radius: 4px;
margin-right: 15px;
-moz-appearance:none;
-webkit-appearance:none;
-o-appearance:none;


}
 
 .forgotpasswordbtn{
font-family: 'Manrope' !important;
font-style: normal;
font-weight: 300;
font-size: 16px;
line-height: 24px;
color: #B5B3B4;
     cursor: pointer;
     margin-right: 5px;
     border: none;
     background-color: transparent;
     padding:5px 0px 0px 0px;
     align-items: center;
     display: flex;
 }




.needsanaccount{
    color: #B5B3B4;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
        cursor: pointer;
        margin-right: 5px;
        margin-top: 30px;
        border: none;
        background-color: transparent;
        padding: 0;
        text-align: center;
        width: 100%;
        display: block;
    }


.needsanaccount span{
    color: #ef5663;
    
}



 
 
 .resetoptionsRight{
    
     padding-top: 5px;
     cursor: pointer;
     margin-right: 5px;
     color: #B5B3B4;
     font-family: 'Manrope';
     font-style: normal;
     font-weight: 400;
     font-size: 16px;
     line-height: 24px;
 }
 
 .resetDescription{
     color:gray;
     font-size: 14px;
     font-weight: 300;
     padding-left: 15px;
     padding-top: 5px !important;
 }

 .pageHeaderSignup{width:100%; max-width:600px; margin:20px 0px;}