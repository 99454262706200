.confirmation-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  inset: 0;
  /* background: #fff; */
  z-index: 1101;
  align-items: center;
  justify-content: center;
  display: none;
}
.confirmation-modal.open {
  display: flex;
}
.confirmation-modal-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  inset: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
}
.confirmation-modal-inner {
  position: relative;
  z-index: 1;
  background-color: #fff;
  max-width: 600px;
  width: 100%;
  border-radius: 5px;
}
.confirmation-modal-header {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #cfd2db;
}
.confirmation-modal-header .header-title {
  flex: 1 1 auto;
}
.confirmation-modal-close {
  cursor: pointer;
  width: 30px;
  height: 30px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.confirmation-modal-body {
  padding: 10px;
}
.confirmation-modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px;
  border-top: 1px solid #cfd2db;
}
.confirmation-modal-footer button {
  margin-left: 10px;
}
