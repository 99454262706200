.chat {
  padding: 60px 0px 0px 65px;
  flex-direction: column;
}

.chat p {
  margin-bottom: 0;
}

.chat_header {
  width: 100%;
  display: flex;
  align-items: center;
}

.chat_left {
  width: 30%;
  padding: 15px;
  padding-left: 0px;
}

.chat_left p {
  font-size: 18px;
  font-weight: 700;
  color: #2c2126;
}

.chat_right {
  width: 70%;
}

.input-icons i {
  position: absolute;
  padding: 10px;
  color: #ef5663;
  font-size: 15px;
  margin-left: 6px;
  border-radius: 8px;
  background-color: #fde6e8;
}

.input-icons {
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: left;

}
.input-icons-msg {
  /* padding: 22px 25px; */
  padding-right: 0;
  position: relative;
  width: 95%;

}

.input-icons-msg input {
  height: 64px;
}

.input-icons-msg .img1 {
  position: absolute;
  margin-left: 10px;
  padding: 10px
}
.input-icons-msg div {
  position: absolute;
  padding: 10px;
  right: 5px;
}

.icon {
  padding: 10px;
  min-width: 40px;
}

.input-field {
  width: 100%;
  padding: 10px;
  /* text-align: center; */
  border: 1px solid #D8D5D6;
  border-radius: 10px;
  padding-left: 63px;
  font-size: 16px;
  font-weight: 400;
  outline: none;
}

.input-field::selection {
  outline: none;
}

.profile_active {
  font-size: 16px;
  color: #B5B3B4;
  font-weight: 400;
}

.profile_name {
  font-size: 18px;
  font-weight: 800;
  line-height: 28px;
  color: #2C2126;
}

.profile_call span {
  padding: 10px;
  background-color: #fde6e8;
  border-radius: 68px;
}

.chat_box {
  border-top: 1.5px solid #d8d5d6;
  /* height: 80vh; */
}

.chat_box_left {
  width: 30%;
  padding: 18px 8px;
  padding-right: 18px;
  border-right: 1.5px solid #d8d5d6;
}

.chat_box_icon1 {
  padding: 10px;
}

.chat_box_icon1 span {
  background-color: #E0E7FF !important;
}

.chat_box_icon1:hover {
  border: 1px solid #ffa6cc;
  border-radius: 8px;
  padding: 9px;
}

.chat_box_icon1:hover span {
  background-color: #ef5663;
}

.chat_box_icon span {
  background-color: #fae8ff;
  border-radius: 68px;
  padding: 10px;
}

.chat_content_head {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #2C2126;
}

.chat_content_status {
  font: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #B5B3B4;
}

.chat_btns button {
  padding: 10px 12px;
  font-size: 12px;
  font-weight: 500;
  color: white;
  border-radius: 7px;
  border: none;
  outline: none;
  background-color: #ef5663;
}

.chat_btns button::selection {
  outline: none;
}

.existing_task {
  font-size: 18px;
  font-weight: 700;
  color: #2C2126;
  line-height: 28px;
  border-bottom: 1.5px solid #d8d5d6;
}

/* .chat_time{
  width: 39%;
} */
.chat_box_right {
  width: 70%;
}

.chat_realTime {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.4);
  margin-top: 24px;
  margin-bottom: 18px;
}

.conversation {
  padding: 0 30px;
  width: 84%;
}

.conversation_details {
  padding: 14px 20px;
  background-color: #ef5663;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: white;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  width: 84%;
}

.conversation_details_right {
  background-color: transparent;
  border: 1.5px solid #D8D5D6;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  color: #B5B3B4;
}
.send_btn div{
  padding: 14px;
  background-color: #ef5663;
  border-radius: 10px;
}
.scroll_existing_task{
  overflow-y: auto;
  overflow-x: hidden;
  height: 59vh;
}
.chat_box_right_scroll{
  overflow-y: auto;
  overflow-x: hidden;
  height: 68vh;
}


.wp-chat-scroll::-webkit-scrollbar {
  width: 5px;
}

.wp-chat-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.wp-chat-scroll::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}