.errorTable{
    flex: 5;
}


.exportbutton{

    background-color: #f9fafc;
    border-radius:20px 20px 0px 0px;
    padding-right: 5px;
    display: flex;
    flex-direction: row-reverse;

}


.exportbutton button.MuiButton-textPrimary{
    color:#ffffff;
    background-color: #1d2740;
    margin:5px 0px;
    padding: 5px 15px;
    font-size: 16px;
    display: flex;
    align-items: center;
    border: none;
    border-radius:  5px 20px 5px 5px;
    color: white;
    cursor: pointer;
    text-decoration: none !important;
    text-transform: capitalize;;

}

